<template>
  <div id="app">
    <div id="header" >

      <b-navbar class="p-4" style="border-bottom: 2px solid #19b394">
        <b-navbar-brand>
          <h1 style="color: #19b394"><strong>Delaware Annual Report</strong></h1>
          <h5 style="color:gray">Data Collection Portal</h5>
        </b-navbar-brand>
      </b-navbar>

    </div>

    <router-view></router-view>

    <div id="footer">

    </div>
<!--    <MyFirstSurvey />-->
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>
