<template>
  <b-container fluid class="pt-5 w-75">
    <b-row>
      <b-col>
        <h3>Filing Selection</h3>
        This site will allow you to quickly provide and verify governance data necessary to file annual reports.
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <b-card-text class="pt-2">
            <b-row>
              <b-col>
                <b-table striped hover :items="entities" :fields="entitiesTableFields">
                  <template #cell(action)="row">
                    <b-button v-if="!row.item.status || (row.item.status === 'DATA_ENTRY_INCOMPLETE')" variant="primary" class="mt-2 mb-2" @click="startGovernance(row.item)">Start</b-button>
                    <b-button v-else-if="(row.item.status === 'DATA_ENTRY_COMPLETE')" variant="success" class="mt-2 mb-2" @click="startGovernance(row.item)">Edit</b-button>
                    <b-badge v-else variant="success" >Complete</b-badge>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>


export default {
  name: 'EntitySelection',
  async mounted() {
    this.$loader.start();
    const magicLink = this.$route.query.magicLink;
    const ttl = this.$route.query.ttl;

    try {
      const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/governance-collector/api/magic-link/${magicLink}`);
      if (response.ok) {
        const data = await response.json();
        console.log(data)
        data.fileNumberUnits.forEach(fileNumberUnit => {this.entities.push({
          name: fileNumberUnit.unitName,
          stateFileNumber: fileNumberUnit.stateFileNumber,
          action: null,
          status: fileNumberUnit.status
        })})
        localStorage.token = magicLink;
        if (ttl) {
          localStorage.ttl = ttl;
        }
      } else if (response.status === 401) {
        console.log("Link expired");
        alert("Your link has expired.")
        //Alert link expired. redirect
        this.$loader.stop();
        this.$router.push({path: "/magic-link"})
      } else {
        console.log(response)
        throw new Error(response)
      }
    } catch (error) {
      //Internet error, please refresh page
      console.error(error)
      alert("Connection error. Please check your internet connection and refresh the page. If this continues, please contact support.")
    } finally {
      this.$loader.stop();
    }

  },
  methods: {
    startGovernance(item) {
      const ttl = localStorage.ttl;
      const currentDate = new Date();
      let expiredDate = new Date(ttl * 1000);
      expiredDate.setHours(expiredDate.getHours() - 4);
      if (currentDate > expiredDate) {
        localStorage.clear();
        alert("Your link is expired. Please try again.")
        this.$router.push({path: "/magic-link"})
      } else {
        this.$router.push({
          path: "/corp-governance-survey",
          query: {
            stateFileNumber: item.stateFileNumber,
            year: this.year,
            jurisdiction: this.jurisdiction
          }
        })
      }
    }
  },
  data() {
    return {
      year: "2025",
      jurisdiction: "Delaware",
      entitiesTableFields: [
        {key: 'name', label: 'Name'},
        {key: 'stateFileNumber', label: 'File Number'},
        {key: 'action', label: 'Action', thClass: 'text-center', tdClass: 'text-center'}
      ],
      entities: [
      ]
    }
  }
}

</script>
