import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueRouter from 'vue-router'
import CorpGovernanceSurvey from "@/components/CorpGovernanceSurvey";
import EntitySelection from "@/components/EntitySelection";
import MagicLink from "@/components/MagicLink";
import 'vue-loading-overlay/dist/vue-loading.css';
import Loader from './plugins/loadingSpinnerPlugin'
import VueIziToast from 'vue-izitoast';
import 'izitoast/dist/css/iziToast.min.css';

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueRouter)
Vue.use(Loader)
Vue.use(VueIziToast);

Vue.config.productionTip = false

const routes = [
  { path: '/', redirect: '/magic-link'},
  { path: '/home', component: EntitySelection},
  { path: '/corp-governance-survey', component: CorpGovernanceSurvey },
  { path: '/magic-link', component: MagicLink }
]

const router = new VueRouter({
  mode: 'hash',
  routes: routes // short for `routes: routes`
})

console.log("V2.2")

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
