<template>
  <div>
    <Survey :survey="survey" />
  </div>
</template>

<script>
  // Default V2 theme
import 'survey-core/defaultV2.min.css';
// Modern theme
// import 'survey-core/modern.min.css';
import { Model, StylesManager } from 'survey-core';
import { Survey } from 'survey-vue-ui';
  import {payloadToSurveyMapper, surveyJson, surveyToPayloadMapper} from "@/assets/js/survey";
  // const SURVEY_ID = 1;

StylesManager.applyTheme("defaultV2");

export default {
  name: 'MyFirstSurvey',
  components: {
    Survey
  },
  beforeRouteLeave (to, from, next) {
    if (this.survey.currentPage.name === "page1" || this.surveyComplete) {
     next();
    } else {
      const answer = window.confirm('Select "OK" to return to the company selection screen. You will lose any unsaved changes.')
      if (answer) {
        next()
      } else {
        next(false)
      }
    }
  },
  async mounted() {
    this.$loader.start();

    try {
      const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/governance-collector/api/filing/${this.$route.query.year}/${this.$route.query.jurisdiction}/${this.$route.query.stateFileNumber}`, {
        headers: {
          'token': localStorage.token
        }
      })

      if (response.ok) {
        const data = await response.json();
        console.log(data)
        this.unitName = data.unitName;
        this.filingYear = data.filingYear;
        this.stateFileNumber = data.stateFileNumber;
        this.jurisdiction = data.jurisdiction;
        if (data.status === "DATA_ENTRY_COMPLETE") {
          this.$toast.warning("Please ensure you re-sign the document on the last page in order to save the edited data.", ' ', this.notificationSystem.options.warning)
        }
        this.survey.data = payloadToSurveyMapper(data)
      } else if (response.status === 401) {
        console.log("Link expired");
        alert("Your link has expired.")
        //Alert link expired. redirect
        this.$loader.stop();
        this.$router.push({path: "/magic-link"})
      } else {
        console.log(response)
        throw new Error(response)
      }
    } catch (error) {
      //Internet error, please refresh page
      console.error(error)
      alert("Connection error. Please check your internet connection and refresh the page. If this continues, please contact support.")
    } finally {
      this.$loader.stop();
    }

  },
  watch: {
    unitName(newVal) {
      if (newVal) {
        this.survey.pages.forEach(page => {
          page.title = this.unitName + " - " + page.title
        })
      }
    }
  },
  methods: {
    onSurveyComplete (sender) {
      console.log("Survey complete")
      console.log(sender.data)
      this.surveyComplete = true;
      const payload = surveyToPayloadMapper(sender.data)
      payload.filingYear = this.filingYear;
      payload.stateFileNumber = this.stateFileNumber;
      payload.jurisdiction = this.jurisdiction;
      payload.status = "DATA_ENTRY_COMPLETE";
      console.log("Payload")
      console.log(payload)
      fetch(`${process.env.VUE_APP_API_BASE_URL}/governance-collector/api/filing`,{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.token
        },
        body: JSON.stringify(payload)
      })
      const splitLocation = window.location.href.split("#")
      splitLocation[1] = "/home?magicLink=" + localStorage.token;
      const nextUrl = splitLocation.join("#");
      let completedHtml = this.survey.completedHtml;
      this.survey.completedHtml = completedHtml.replace("<HOME_ANCHOR>", nextUrl);
      this.surveyComplete = true;
    },
  },
  data() {

    //https://dev-governance-collector-frontend.s3.amazonaws.com/index.html#/magic-link
    let survey = new Model(surveyJson);
    survey.onComplete.add(this.onSurveyComplete);

    return {
      unitName: '',
      filingYear: '',
      stateFileNumber: '',
      jurisdiction: '',
      prefilledData: {},
      survey,
      surveyComplete: false,
      notificationSystem: {
        options: {
          warning: {
            position: 'center',
            timeout: false
          }
        }
      }
    }
  }
}
</script>
