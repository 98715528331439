<template>
  <div>
    <b-container fluid class="custom-width pt-5">
      <b-card class="bg-light" title="Secure Link" sub-title="Please enter your email address.
          You will receive a link that will allow you to update your business data for annual report filings.
          This link will expire in 2 hours.">
        <b-card-text>
          <b-row>
            <b-col class="d-flex justify-content-center">
              <b-form @submit="onSubmit" class="custom-width">
                <b-form-group class="mt-2">
                  <b-form-input type="email" v-model="email" id="email-input" placeholder="Enter your email address"></b-form-input>
                </b-form-group>
                <b-form-group class="mt-3">
                  <b-button type="submit" variant="primary">Submit</b-button>
                </b-form-group>
              </b-form>
            </b-col>
          </b-row>
          <b-row v-if="errorMessage">
            <b-col class="alert-danger">
              {{errorMessage}}
            </b-col>
          </b-row>
          <b-row v-if="success">
            <b-col class="alert-success">
              Email sent.
            </b-col>
          </b-row>
        </b-card-text>

      </b-card>
      <b-row v-if="magicLink && devMode" class="pt-5 text-center">
        <b-col class="alert-warning" style="border: solid 2px orange;">
          This link is displayed here for testing purposes only. Normally this link would be emailed to the user.
          <br>
          <a :href="magicLink">Click here to continue to the next page in the workflow.</a>
        </b-col>
      </b-row>
      <b-row>
        <b-button v-if="devMode" @click="reset">Reset test data</b-button>
      </b-row>
    </b-container>

  </div>
</template>

<script>


export default {
  name: 'MagicLink',
  mounted() {
    this.errorMessage = "";
    this.success = false;
  },
  methods: {
    reset() {
      //testing only
      this.$loader.start();
      fetch(`${process.env.VUE_APP_API_BASE_URL}/governance-collector/api/reset-test`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: this.email,
        })
      }).then(() => {
        this.$loader.stop();
        alert(`Reset test data for email: ${this.email}`)
      }).catch(() => {
        this.$loader.stop();
        alert("Error");
      })
    },
    onSubmit(event) {
      event.preventDefault()

      this.errorMessage = "";
      this.success = false;

      if (!this.email) {
        this.errorMessage = "Please enter an email address";
        return;
      }

      this.$loader.start();
      console.log("Form submit");
      fetch(`${process.env.VUE_APP_API_BASE_URL}/governance-collector/api/magic-link`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: this.email,
          year: this.year,
          jurisdiction: this.jurisdiction
        })
      })
          .then(response => {
            if (response.ok) {
              return response.json()
            } else {
              throw (`${response.status} error`)
            }
          })
          .then(response => {
            console.log(response);
            if (response.error) {
              this.errorMessage = response.message + " If you believe you've received this message in error, please contact us."
            } else {
              this.magicLink = window.location.href.split("magic-link")[0] + 'home?magicLink=' + response.id + "&ttl=" + response.ttl;
              this.success = true;
            }
          })
          .catch(error => {
            console.error(error);
            this.errorMessage = "An error has occured. Please check your internet connection and try again. If this continues, please contact us."
          })
          .finally(() => {
            this.$loader.stop();
          })
    }
  },
  computed: {
    devMode() {
      return process.env.VUE_APP_DEV_MODE === 'true'
    }
  },
  data() {
    return {

      year: "2025",
      email: "",
      jurisdiction: "Delaware",
      magicLink: '',
      errorMessage: '',
      success: false

    }
  }
}

</script>

<style>
.custom-width {
  width: 60%;
}

.error-text {
  color: red;
}
</style>
