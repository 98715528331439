import Loading from "vue-loading-overlay";

//import Loader from <this file>
//Vue.use(Loader)
//Vue.$loader.start();
//Vue.$loader.stop();

export default {

  install(Vue) {

    Vue.use(Loading);

    const loader = {spinner: null,  isSpinning: false};

    loader.start = function() {
      this.spinner = Vue.$loading.show({
        opacity: .4,
        backgroundColor: '#808080',
        transition: ''
      });
      this.isSpinning = true;
    }


    loader.stop = function() {
      if (this.spinner) {
        this.spinner.hide();
      }
      this.isSpinning = false;
    }

    Vue.prototype.$loader = loader;
  }

}
